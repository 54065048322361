/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_appsync_graphqlEndpoint": "https://mogoiyupujhxladioz7ia6ajpa.appsync-api.us-east-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-2",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-shczi6kl2rawrgzy5yorep5h3q",
    "aws_cloud_logic_custom": [
        {
            "name": "carsnipeRESTAPI",
            "endpoint": "https://vrp6vlj9ek.execute-api.us-east-2.amazonaws.com/dev",
            "region": "us-east-2"
        }
    ],
    "aws_cognito_identity_pool_id": "us-east-2:e8e76009-8ab0-4374-a492-468122daa4c1",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_jrCbKz7PH",
    "aws_user_pools_web_client_id": "4jmd5vld7mjp3rnp4if67pmn7e",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "musicbucket2d51b-dev",
    "aws_user_files_s3_bucket_region": "us-east-2"
};


export default awsmobile;
